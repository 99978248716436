.menuBar {
  line-height: 60px;
  margin-left: 60px;
  background: transparent;
  border: none;
  z-index: 999;
  height: 60px;
  width:100%;
  :global {
    .ant-menu-item:nth-of-type(3){
      width:110px;
    }
    .ant-menu-item a {
      color: #222222 ;
    }

    .ant-menu-item-selected {
      font-weight: bold;
      color: #FF671D;
    }


    .ant-menu-horizontal > .ant-menu-item .ant-menu-title-content a {
      color: #222222;
    }

    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after {
      border-bottom: 2px solid transparent;
      width: 24px;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .ant-menu-item-active::after,
    .ant-menu-submenu-active::after {
      display: none;
    }
  }
}

@primary-color: #FA6400;