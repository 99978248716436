.headerBar {
    height: 60px;
    width: 100%;
    min-width: 1376px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FEFEFE;
    padding: 0 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    box-shadow: 8px 2px 7px 2px rgba(185,185,185,0.44);
}

.website-title {
    color:#535759;
    margin-right: 60px;
    width: 78px;
    height: 26px;
    font-size: 26px;
    font-weight: 300;
    line-height: 26px;
}


.layout {
    background: transparent;
    min-width: 1376px;
}

@primary-color: #FA6400;